.allBackground {
    height: auto;
}

.supportBtn {
    background-color: blueviolet;
    color: white;
    width: 10em;
    height: 3em;
    border-radius: 8px;
    width: 188px;
    margin-top: 15px;
    float: right;
    margin-right: 2em;
}

.landImg {
    margin-left: 50%;
}

.txs {
    font-weight: bolder;
    position: relative;
    bottom: 3em;
}



.tx1 {
    color: white;
    font-size: 25px;
    text-align: justify;
}

.tx12 {
    color: white;
    font-size: 30px;
    text-align: justify;
}



/* @media only screen and (max-width: 390px){
	.allBackground{
        height: 450em;
    }
    
} 


@media only screen and (max-width: 414px){
	.allBackground{
        height: 450em;
    }
    
}  */



.bottonGradient {
    background-image: linear-gradient(96.64deg, #8F01FC -1.68%, rgba(48, 17, 237, 0) 111.25%);
    width: 14em;
    color: white;
    height: 3em;
    margin-top: 1em;
    border-radius: 7px;
    margin-bottom: 1em;

}

.btngradient {
    background-image: linear-gradient(96.64deg, #8F01FC -1.68%, rgba(0, 0, 0, 0) 111.25%);
    width: 16em;
    color: white;
    height: 3em;
    height: 5em;
    margin-bottom: 1em;
}

.tokentxt {
    color: #FFE700;
    font-size: 20px;
    margin-bottom: 4em;
}

h1,
h2,
h3 {
    color: white;
}

.txtabout {
    font-size: 16px;
}

.henesis {
    color: #6000AA;
}


.o {
    font-size: 70px;

}

.boxModel {
    position: relative;
    background-image: linear-gradient(96.64deg, #8F01FC -1.68%, rgba(0, 0, 0, 0) 111.25%);
    height: 18em;
    width: 16em;
    margin-left: 2em;
    margin-bottom: 2em;
    border-radius: 10px;

}

.txheads {
    font-size: 25px;
    position: relative;
    top: 1em;
}

.titles {
    font-size: 20px;
}

.boxtitle {
    margin-top: 2em;
}