@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: orbitron-header;
    src: url('../public/Orbitron-Black.ttf');
}

@layer utilities {
    .pictures {
        width: 33em;
    }

    .purple {
        color: #6000AA;
    }
    .yellow{
        color: #FADF00;
    }
    .white{
        color: white;
    }
    .smalls{
        font-size: 20px;
    }

    .justifier{
        text-align: justify;
    }
}

.orbitron-header {
    font-family: orbitron-header;
}

@font-face {
    font-family: orbitron-light;
    src: url('../public/Orbitron-Regular.ttf');
}

.orbitron-light {
    font-family: orbitron-light;
}